<h2 mat-dialog-title>Planungstag ändern</h2>
<mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label>Beschreibung</mat-label>
        <input matInput [(ngModel)]="pt.beschreibung">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Abkürzung</mat-label>
        <input matInput [(ngModel)]="pt.abkuerzung">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Datum</mat-label>
        <input matInput [(ngModel)]="pt.datum" [matDatepicker]="pickerDatum">
        <mat-hint>dd.MM.yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="pickerDatum"></mat-datepicker-toggle>
        <mat-datepicker #pickerDatum></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>gültig ab</mat-label>
        <input matInput [(ngModel)]="pt.datumStart" [matDatepicker]="pickerDatumStart">
        <mat-hint>dd.MM.yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="pickerDatumStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerDatumStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>gültig bis</mat-label>
        <input matInput [(ngModel)]="pt.datumEnde" [matDatepicker]="pickerDatumEnde">
        <mat-hint>dd.MM.yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="pickerDatumEnde"></mat-datepicker-toggle>
        <mat-datepicker #pickerDatumEnde></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Tags</mat-label>
        <mat-chip-grid #tagGrid>
            <mat-chip-row *ngFor="let tag of pt.tags"
                (removed)="removeTag(tag)"
                [editable]="true"
                (edited)="editTag(tag, $event)">
                {{tag}}
                <button matChipRemove><mat-icon>cancel</mat-icon></button>
            </mat-chip-row>
            <input placeholder="neuer Tag"
                [matChipInputFor]="tagGrid"
                [matChipInputAddOnBlur]="addOnBlur"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addTag($event)">
        </mat-chip-grid>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
        <button mat-button (click)="close(pt)">Speichern</button>
        <button mat-button mat-dialog-close>Abbruch</button>
</mat-dialog-actions>
