import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Oeffnungszeiten, Zeiten } from '../api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-oeffnungszeit-wochentag',
  templateUrl: './oeffnungszeit-wochentag.component.html',
  styleUrls: ['./oeffnungszeit-wochentag.component.scss']
})
export class OeffnungszeitWochentagComponent implements OnInit {
  @Input("wochentag")
  wochentag!: Oeffnungszeiten;

  @Output("delete")
  deleteEvent = new EventEmitter<Oeffnungszeiten>();

  ngOnInit(): void {
    console.debug("Wochentag:", this.wochentag);
    if(!this.wochentag) this.wochentag = {
      wochentag: "",
      htmlId: "",
      zeitspanne: []
    };
  }

  addZeitspanne(): void {
    this.wochentag.zeitspanne.push({zeit: ""});
  }

  zeitspanneDropped(event: CdkDragDrop<Zeiten>): void {
    console.log("DragDrop zeitspanne:", event);
    if(this.wochentag.zeitspanne) {
      moveItemInArray(this.wochentag.zeitspanne, event.previousIndex, event.currentIndex);
    }
  }

  deleteZeitspanne(index: number): void {
    this.wochentag.zeitspanne.splice(index, 1);
  }

  deleteMe(wochentag: Oeffnungszeiten): void {
    this.deleteEvent.emit(wochentag);
  }
}
