<div>
    <button mat-button (click)="create()"><mat-icon>add</mat-icon>Neue Öffnungszeit</button>
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10vh">

    <mat-card fxFlex *ngFor="let ozg of oeffnungszeiten | async" class="card" fxFlexFill>
        <mat-card-header>
            <mat-icon mat-card-avatar>schedule</mat-icon>
            <mat-card-title>{{ozg.beschreibung}}</mat-card-title>
        </mat-card-header>
            <mat-form-field>
                <mat-label>Beschreibung</mat-label>
                <input matInput readonly="true" [value]="ozg.beschreibung">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Anmerkung</mat-label>
                <input matInput readonly="true" [value]="ozg.anmerkung===undefined ? null : ozg.anmerkung">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Sortierung</mat-label>
                <input matInput readonly="true" [value]="ozg.sort">
            </mat-form-field>
            <app-oeffnungszeiten-zeiten *ngFor="let oz of ozg.oeffnungszeiten" [oeffnungszeit]="oz"></app-oeffnungszeiten-zeiten>
            <mat-form-field>
                <mat-label>Tags</mat-label>
                <mat-chip-grid #tagGrid>
                    <mat-chip-row *ngFor="let tag of ozg.tags">
                        {{tag}}
                    </mat-chip-row>
                    <input hidden placeholder="neuer Tag" readonly="true" [matChipInputFor]="tagGrid">
                </mat-chip-grid>
            </mat-form-field>
        <mat-card-actions>
            <button mat-icon-button matTooltip="Eintrag bearbeiten" color="primary" (click)="edit(ozg)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button matTooltip="Eintrag löschen" color="warn" (click)="delete(ozg)"><mat-icon>delete</mat-icon></button>
        </mat-card-actions>
    </mat-card>
</div>
