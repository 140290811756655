import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ansprechpartner, AnsprechpartnerControllerService } from '../api';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AnsprechpartnerDeleteComponent } from '../ansprechpartner-delete/ansprechpartner-delete.component';
import { AnsprechpartnerEditComponent } from '../ansprechpartner-edit/ansprechpartner-edit.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ansprechpartner-overview',
  templateUrl: './ansprechpartner-overview.component.html',
  styleUrls: ['./ansprechpartner-overview.component.scss']
})
export class AnsprechpartnerOverviewComponent implements OnInit, OnDestroy {

  ansprechpartner!: Observable<Ansprechpartner[]>;
  ansprechpartnerSubject: BehaviorSubject<Ansprechpartner[]> = new BehaviorSubject<Ansprechpartner[]>([]);
  ansprechpartner$!: Ansprechpartner[];

  constructor(private ansprechpartnerService: AnsprechpartnerControllerService, private dialog: MatDialog, private snakbar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.ansprechpartner = this.ansprechpartnerSubject.asObservable();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.ansprechpartnerSubject.unsubscribe();
  }

  private loadData(): void {
    (this.ansprechpartnerService.getAnsprechpartnerAll() as Observable<Ansprechpartner[]>).subscribe({
      next: (d) => {
        this.ansprechpartner$ = d;
        this.ansprechpartnerSubject.next(this.ansprechpartner$);
      }
    });
  }

  public edit(ap: Ansprechpartner): void {
    console.log("Edit:", ap);
    const dialogRef = this.dialog.open(AnsprechpartnerEditComponent, {
      data:ap,
      width: "100vw",
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        console.log("Dialog close:", result);
        if(result) {
          this.ansprechpartnerService.updateAnsprechpartner(result.id, result).subscribe({
            next: (api) => {
              this.ansprechpartner$ = this.ansprechpartner$.map(ap => ap.id !== api.id ? ap : api)
              this.ansprechpartnerSubject.next(this.ansprechpartner$);
            },
            error: (err) => {
              console.error(err);
              this.snakbar.open(err.message, "Okay");
            }
          })
        };
      }
    })
  }

  public delete(ap: Ansprechpartner): void {
    console.log("Delete:", ap);
    const dialogRef = this.dialog.open(AnsprechpartnerDeleteComponent, {
      data: ap,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        console.log("Dialog close:", result);
        if(result) {
          this.ansprechpartnerService.deleteAnsprechpartner(result.id).subscribe({
            next: (api) => {
              this.ansprechpartner$ = this.ansprechpartner$.filter((element) => element.id !== result.id);
              this.ansprechpartnerSubject.next(this.ansprechpartner$);
            },
            error: (err) => {
              console.error(err);
              this.snakbar.open(err.message, "Okay");
            }
          })
        }
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(AnsprechpartnerEditComponent, {
      data:{tags: []},
      width: "100vw",
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        console.log("Dialog close:", result);
        if(result) {
          this.ansprechpartnerService.createAnsprechpartner(result).subscribe({
            next: (api) => {
              console.log("API:", api);
              this.ansprechpartner$.push(api)
              this.ansprechpartnerSubject.next(this.ansprechpartner$);
            },
            error: (err) => {
              console.error(err);
              this.snakbar.open(err.message, "Okay");
            }
          });
        };
      }
    })
  }
}
