<div fxLayout="column">
    <div fxLayout="row" fxFlex>
        <mat-form-field fxFlex="auto">
            <mat-label>Wochentag</mat-label>
            <input matInput [(ngModel)]="wochentag.wochentag">
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <mat-label>htmlId</mat-label>
            <input matInput [(ngModel)]="wochentag.htmlId">
        </mat-form-field>
        <button mat-icon-button (click)="deleteMe(wochentag)" color="warn"><mat-icon>delete</mat-icon></button>
    </div>
    <div fxLayout="column" fxFlex="auto" class="zeitspanne">
        <div fxFlex>
            <button mat-button (click)="addZeitspanne()" color="primary"><mat-icon>add</mat-icon>Neue Zeitangabe</button>
        </div>
        <div fxLayout="column" fxFlex cdkDropList (cdkDropListDropped)="zeitspanneDropped($event)">
            <div fxLayout="row" *ngFor="let z of wochentag.zeitspanne; index as i" fxFlex cdkDrag>
                <mat-form-field fxFlex>
                    <mat-label>Zeitspanne</mat-label>
                    <input matInput [(ngModel)]="z.zeit">
                </mat-form-field>
                <button mat-icon-button (click)="deleteZeitspanne(i)" color="warn"><mat-icon>delete</mat-icon></button>
            </div>
        </div>
    </div>
</div>
