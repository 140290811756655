<div>
    <button mat-button (click)="create()"><mat-icon>add</mat-icon>Neuer Ansprechpartner</button>
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10vh">

    <mat-card fxFlex *ngFor="let ap of ansprechpartner | async" class="card" fxFlexFill>
        <mat-card-header>
            <mat-icon mat-card-avatar>person</mat-icon>
            <mat-card-title>{{ap.name}}</mat-card-title>
        </mat-card-header>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput readonly="true" [value]="ap.name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput readonly="true" [value]="ap.phone===undefined ? null : ap.phone">
            </mat-form-field>
            <mat-form-field>
                <mat-label>E-Mail</mat-label>
                <input matInput readonly="true" [value]="ap.mail===undefined ? null : ap.mail">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Abteilung</mat-label>
                <input matInput readonly="true" [value]="ap.department===undefined ? null : ap.department">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Sortierung</mat-label>
                <input matInput readonly="true" [value]="ap.sort">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tags</mat-label>
                <mat-chip-grid #tagGrid>
                    <mat-chip-row *ngFor="let tag of ap.tags">
                        {{tag}}
                    </mat-chip-row>
                    <input hidden placeholder="neuer Tag" readonly="true" [matChipInputFor]="tagGrid">
                </mat-chip-grid>
            </mat-form-field>
        <mat-card-actions>
            <button mat-icon-button matTooltip="Eintrag bearbeiten" color="primary" (click)="edit(ap)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button matTooltip="Eintrag löschen" color="warn" (click)="delete(ap)"><mat-icon>delete</mat-icon></button>
        </mat-card-actions>
    </mat-card>

    <!--
    <mat-grid-list cols="3">
        <mat-grid-tile *ngFor="let ap of ansprechpartner | async">
            <mat-card class="card">
                <mat-card-header>
                    <mat-card-title>{{ap.name}}</mat-card-title>
                </mat-card-header>
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput readonly="true" [value]="ap.name">
                </mat-form-field>
                <mat-card-actions>
                    <button mat-icon-button color="primary"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button color="warn"><mat-icon>delete</mat-icon></button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
    -->
</div>
