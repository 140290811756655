<h2 mat-dialog-title>Ansprechpartner ändern</h2>
<mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="ap.name">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Telefon</mat-label>
        <input matInput [(ngModel)]="ap.phone">
    </mat-form-field>
    <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput [(ngModel)]="ap.mail">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Abteilung</mat-label>
        <input matInput [(ngModel)]="ap.department">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Sortierung</mat-label>
        <input type="number" matInput [(ngModel)]="ap.sort">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Tags</mat-label>
        <mat-chip-grid #tagGrid>
            <mat-chip-row *ngFor="let tag of ap.tags"
                (removed)="removeTag(tag)"
                [editable]="true"
                (edited)="editTag(tag, $event)">
                {{tag}}
                <button matChipRemove><mat-icon>cancel</mat-icon></button>
            </mat-chip-row>
            <input placeholder="neuer Tag"
                [matChipInputFor]="tagGrid"
                [matChipInputAddOnBlur]="addOnBlur"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addTag($event)">
        </mat-chip-grid>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
        <button mat-button [mat-dialog-close]="ap">Speichern</button>
        <button mat-button mat-dialog-close>Abbruch</button>
</mat-dialog-actions>
