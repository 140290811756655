<div>
    <button mat-button (click)="create()"><mat-icon>add</mat-icon>Neuer Planungstag</button>
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10vh">

    <mat-card fxFlex *ngFor="let pt of planungstage | async" class="card" fxFlexFill>
        <mat-card-header>
            <mat-icon mat-card-avatar>event</mat-icon>
            <mat-card-title>{{pt.beschreibung}}</mat-card-title>
        </mat-card-header>
            <mat-form-field>
                <mat-label>Beschreibung</mat-label>
                <input matInput readonly="true" [value]="pt.beschreibung">
            </mat-form-field>
            <mat-form-field>    
                <mat-label>Abkürzung</mat-label>
                <input matInput readonly="true" [value]="pt.abkuerzung===undefined ? null : pt.abkuerzung">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <input matInput readonly="true" [value]="pt.datum===undefined ? null : pt.datum | date:'yyyy-MM-dd'">
            </mat-form-field>
            <mat-form-field>
                <mat-label>gültig ab</mat-label>
                <input matInput readonly="true" [value]="pt.datumStart===undefined ? null : pt.datumStart | date:'yyyy-MM-dd'">
            </mat-form-field>
            <mat-form-field>
                <mat-label>gültig bis</mat-label>
                <input matInput readonly="true" [value]="pt.datumEnde===undefined ? null : pt.datumEnde | date:'yyyy-MM-dd'">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tags</mat-label>
                <mat-chip-grid #tagGrid>
                    <mat-chip-row *ngFor="let tag of pt.tags">
                        {{tag}}
                    </mat-chip-row>
                    <input hidden placeholder="neuer Tag" readonly="true" [matChipInputFor]="tagGrid">
                </mat-chip-grid>
            </mat-form-field>
        <mat-card-actions>
            <button mat-icon-button matTooltip="Eintrag bearbeiten" color="primary" (click)="edit(pt)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button matTooltip="Eintrag löschen" color="warn" (click)="delete(pt)"><mat-icon>delete</mat-icon></button>
        </mat-card-actions>
    </mat-card>

    <!--
    <mat-grid-list cols="3">
        <mat-grid-tile *ngFor="let ap of ansprechpartner | async">
            <mat-card class="card">
                <mat-card-header>
                    <mat-card-title>{{ap.name}}</mat-card-title>
                </mat-card-header>
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput readonly="true" [value]="ap.name">
                </mat-form-field>
                <mat-card-actions>
                    <button mat-icon-button color="primary"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button color="warn"><mat-icon>delete</mat-icon></button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
    -->
</div>
