import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { HomeComponent } from './home/home.component';

import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AnsprechpartnerOverviewComponent } from './ansprechpartner-overview/ansprechpartner-overview.component';
import { OeffnungszeitenOverviewComponent } from './oeffnungszeiten-overview/oeffnungszeiten-overview.component';
import { PlanungstageOverviewComponent } from './planungstage-overview/planungstage-overview.component';

const routes: Routes = [
  {
    path: "ansprechpartner_overview",
    component: AnsprechpartnerOverviewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "oeffnungszeiten_overview",
    component: OeffnungszeitenOverviewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "planungstage_overview",
    component: PlanungstageOverviewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "login-failed",
    component: LoginFailedComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "auth",
    component: MsalRedirectComponent,
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [MsalGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal    
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
