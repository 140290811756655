/**
 * Web-API
 * EUV API für Allgemeinnformationen
 *
 * The version of the OpenAPI document: DEV
 * Contact: marcus.schneider@euv-stadtbetrieb.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Planungstag { 
    id?: string;
    beschreibung: string;
    abkuerzung: string;
    datum?: string;
    datumStart?: string;
    datumEnde?: string;
    tags?: Array<string>;
}

