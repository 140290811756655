import { Component, Inject, Input, OnInit } from '@angular/core';
import { Oeffnungszeiten } from '../api';

@Component({
  selector: 'app-oeffnungszeiten-zeiten',
  templateUrl: './oeffnungszeiten-zeiten.component.html',
  styleUrls: ['./oeffnungszeiten-zeiten.component.scss']
})
export class OeffnungszeitenZeitenComponent implements OnInit {

  @Input("oeffnungszeit")
  oeffnungszeit!: Oeffnungszeiten;

  ngOnInit(): void {
    console.log("OZ", this.oeffnungszeit);
  }
}
