import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter'
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ApiModule } from './api/api.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

import { LoginFailedComponent } from './login-failed/login-failed.component';
import { HomeComponent } from './home/home.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { AnsprechpartnerOverviewComponent } from './ansprechpartner-overview/ansprechpartner-overview.component';
import { AnsprechpartnerEditComponent } from './ansprechpartner-edit/ansprechpartner-edit.component';
import { AnsprechpartnerDeleteComponent } from './ansprechpartner-delete/ansprechpartner-delete.component';
import { OeffnungszeitenOverviewComponent } from './oeffnungszeiten-overview/oeffnungszeiten-overview.component';
import { OeffnungszeitenEditComponent } from './oeffnungszeiten-edit/oeffnungszeiten-edit.component';
import { OeffnungszeitenDeleteComponent } from './oeffnungszeiten-delete/oeffnungszeiten-delete.component';
import { OeffnungszeitenZeitenComponent } from './oeffnungszeiten-zeiten/oeffnungszeiten-zeiten.component';
import { PlanungstageOverviewComponent } from './planungstage-overview/planungstage-overview.component';
import { PlanungstageEditComponent } from './planungstage-edit/planungstage-edit.component';
import { PlanungstageDeleteComponent } from './planungstage-delete/planungstage-delete.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OeffnungszeitWochentagComponent } from './oeffnungszeit-wochentag/oeffnungszeit-wochentag.component';
import { Configuration, ConfigurationParameters } from './api';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: '/auth',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}

export function ApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiConfig.uri,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFailedComponent,
    HomeComponent,
    TopMenuComponent,
    AnsprechpartnerOverviewComponent,
    AnsprechpartnerEditComponent,
    AnsprechpartnerDeleteComponent,
    OeffnungszeitenOverviewComponent,
    OeffnungszeitenEditComponent,
    OeffnungszeitenDeleteComponent,
    OeffnungszeitenZeitenComponent,
    PlanungstageOverviewComponent,
    PlanungstageEditComponent,
    PlanungstageDeleteComponent,
    OeffnungszeitWochentagComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatLuxonDateModule,
    FlexLayoutModule,
    ApiModule.forRoot(ApiConfigFactory),
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    DragDropModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "de-DE",
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
