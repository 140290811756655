import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ansprechpartner } from '../api';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-ansprechpartner-edit',
  templateUrl: './ansprechpartner-edit.component.html',
  styleUrls: ['./ansprechpartner-edit.component.scss']
})
export class AnsprechpartnerEditComponent {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  ap: Ansprechpartner = {
    name: "",
    sort: 0
  };

  constructor(public dialogRef: MatDialogRef<AnsprechpartnerEditComponent>, @Inject(MAT_DIALOG_DATA)private data: Ansprechpartner) {
    if(data) this.ap = cloneDeep(data);
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if(value) {
      this.ap.tags?.push(value);
    }
    event.chipInput!.clear();
  }

  removeTag(tag: string): void {
    const index = this.ap.tags?.indexOf(tag);
    if(index !== undefined && index >= 0) {
      this.ap.tags?.splice(index, 1);
    }
  }

  editTag(tag: string, event: MatChipEditedEvent): void {
    const value = event.value.trim();
    if(!value) {
      this.removeTag(tag);
      return;
    }

    const index = this.ap.tags?.indexOf(tag);
    if(index !== undefined && index >= 0 && this.ap.tags) {
      this.ap.tags[index] = value;
    }
  }
}
