import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Planungstag, PlanungstagControllerService } from '../api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PlanungstageDeleteComponent } from '../planungstage-delete/planungstage-delete.component';
import { PlanungstageEditComponent } from '../planungstage-edit/planungstage-edit.component';

@Component({
  selector: 'app-planungstage-overview',
  templateUrl: './planungstage-overview.component.html',
  styleUrls: ['./planungstage-overview.component.scss']
})
export class PlanungstageOverviewComponent implements OnInit, OnDestroy {

  planungstage!: Observable<Planungstag[]>;
  planungstageSubject: BehaviorSubject<Planungstag[]> = new BehaviorSubject<Planungstag[]>([]);
  planungstage$!: Planungstag[];

  constructor(private planungstagService: PlanungstagControllerService, private dialog: MatDialog, private snakbar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.planungstage = this.planungstageSubject.asObservable();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.planungstageSubject.unsubscribe();
  }

  private loadData(): void {
    (this.planungstagService.getPlanungstagAll() as Observable<Planungstag[]>).subscribe({
      next: (result) => {
        this.planungstage$ = result;
        this.planungstageSubject.next(this.planungstage$);
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(PlanungstageEditComponent, {
      data: {
        tags: []
      },
      width: "100vw"
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Add:", result);
          this.planungstagService.createPlanungstag(result).subscribe({
            next: (api) => {
              this.planungstage$.push(api);
              this.planungstageSubject.next(this.planungstage$);
            }
          });
        }
      },
      error: (err) => {
        this.snakbar.open(err.message, "Okay");
      },
    });
  }
  
  public edit(pt: Planungstag): void {
    const dialogRef = this.dialog.open(PlanungstageEditComponent, {
      data: pt,
      width: "100vw"
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Edit:", result);
          this.planungstagService.updatePlanungstag(result.id, result).subscribe({
            next: (api) => {
              this.planungstage$ = this.planungstage$.map(pt => pt.id !== api.id ? pt : api);
              this.planungstageSubject.next(this.planungstage$);
            }
          });
        }
      },
      error: (err) => {
        this.snakbar.open(err.message, "Okay");
      },
    });
  }

  public delete(pt: Planungstag): void {
    const dialogRef = this.dialog.open(PlanungstageDeleteComponent, {
      data: pt,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Delete:", result);
          this.planungstagService.deletePlanungstag(result.id).subscribe({
            next: (api) => {
              this.planungstage$ = this.planungstage$.filter((element) => element.id !== result.id);
              this.planungstageSubject.next(this.planungstage$);
            }
          });
        }
      },
      error: (err) => {
        this.snakbar.open(err.message, "Okay");
      },
    });
  }
}
