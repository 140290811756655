<h2 mat-dialog-title>Öffnungszeit ändern</h2>
<mat-dialog-content>
    <div fxLayout="column">
        <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <input matInput [(ngModel)]="ozg.beschreibung">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Anmerkung</mat-label>
            <input matInput [(ngModel)]="ozg.anmerkung">
        </mat-form-field>
        <div class="wochentage">
            <button mat-button (click)="createWochentag()" color="primary"><mat-icon>add</mat-icon>Neuer Wochentag</button>
        </div>
        <div class="wochentage" cdkDropList (cdkDropListDropped)="dropWochentag($event)">
            <app-oeffnungszeit-wochentag *ngFor="let wt of ozg.oeffnungszeiten; index as i" [wochentag]="wt" (delete)="deleteWochentag(i, $event)" cdkDrag></app-oeffnungszeit-wochentag>
        </div>
        <mat-form-field>
            <mat-label>Sortierung</mat-label>
            <input matInput type="number" [(ngModel)]="ozg.sort">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tags</mat-label>
            <mat-chip-grid #tagGrid>
                <mat-chip-row *ngFor="let tag of ozg.tags"
                    (removed)="removeTag(tag)"
                    [editable]="true"
                    (edited)="editTag(tag, $event)">
                    {{tag}}
                    <button matChipRemove><mat-icon>cancel</mat-icon></button>
                </mat-chip-row>
                <input placeholder="neuer Tag"
                    [matChipInputFor]="tagGrid"
                    [matChipInputAddOnBlur]="addOnBlur"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTag($event)">
            </mat-chip-grid>
            </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="ozg">Speichern</button>
    <button mat-button mat-dialog-close>Abbruch</button>
</mat-dialog-actions>
