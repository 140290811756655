import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Oeffnungszeiten, OeffnungszeitenControllerService, OeffnungszeitenGebaeude } from '../api';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OeffnungszeitenDeleteComponent } from '../oeffnungszeiten-delete/oeffnungszeiten-delete.component';
import { OeffnungszeitenEditComponent } from '../oeffnungszeiten-edit/oeffnungszeiten-edit.component';

@Component({
  selector: 'app-oeffnungszeiten-overview',
  templateUrl: './oeffnungszeiten-overview.component.html',
  styleUrls: ['./oeffnungszeiten-overview.component.scss']
})
export class OeffnungszeitenOverviewComponent implements OnInit, OnDestroy {
  oeffnungszeiten!: Observable<OeffnungszeitenGebaeude[]>;
  oeffnungszeitenSubject: BehaviorSubject<OeffnungszeitenGebaeude[]> = new BehaviorSubject<OeffnungszeitenGebaeude[]>([]);
  oeffnungszeiten$!: OeffnungszeitenGebaeude[];

  constructor(private oeffnungszeitenService: OeffnungszeitenControllerService, private dialog: MatDialog, private snakbar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.oeffnungszeiten = this.oeffnungszeitenSubject.asObservable();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.oeffnungszeitenSubject.unsubscribe();
  }

  private loadData(): void {
    (this.oeffnungszeitenService.findAll() as Observable<OeffnungszeitenGebaeude[]>).subscribe({
      next: (result) => {
        this.oeffnungszeiten$ = result;
        this.oeffnungszeitenSubject.next(this.oeffnungszeiten$);
        console.debug("OZG:", this.oeffnungszeiten$);
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(OeffnungszeitenEditComponent, {
      data: {
        beschreibung: "",
        tags: [],
        oeffnungszeiten: [],
      },
      width: "100vw",
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Add:", result);
          this.oeffnungszeitenService.createOeffnungszeiten(result).subscribe({
            next: (api) => {
              this.oeffnungszeiten$.push(api);
              this.oeffnungszeitenSubject.next(this.oeffnungszeiten$);
            },
            error: (err) => {
              this.snakbar.open(err.message, "Okay");
            }
          });
        }
      }
    });
  }

  public edit(ozg: OeffnungszeitenGebaeude): void {
    const dialogRef = this.dialog.open(OeffnungszeitenEditComponent, {
      data:ozg,
      width: "100vw",
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Edit:", result);
          this.oeffnungszeitenService.updateOeffnungszeiten(result.id, result).subscribe({
            next: (api) => {
              this.oeffnungszeiten$ = this.oeffnungszeiten$.map(ozg => ozg.id !== api.id ? ozg : api);
              this.oeffnungszeitenSubject.next(this.oeffnungszeiten$);
            },
            error: (err) => {
              this.snakbar.open(err.message, "Okay");
            }
          });
        }
      }
    });
  }

  public delete(ozg: OeffnungszeitenGebaeude): void {
    const dialogRef = this.dialog.open(OeffnungszeitenDeleteComponent, {
      data: ozg
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result) {
          console.debug("Delete:", result);
          this.oeffnungszeitenService.deleteOeffnungszeitenGebaeude(result.id).subscribe({
            next: (api) => {
              this.oeffnungszeiten$ = this.oeffnungszeiten$.filter((element) => element.id !== result.id);
              this.oeffnungszeitenSubject.next(this.oeffnungszeiten$);
            },
            error: (err) => {
              this.snakbar.open(err.message, "Okay");
            },
          });
        }
      }
    });
  }
}
