export const environment = {
    msalConfig: {
        auth: {
            clientId: '662d0183-0a0d-4807-8a96-251d2997e692',
            authority: 'https://login.microsoftonline.com/c39de00a-b33d-48a1-b0ad-27ce9f5a89b6'
        }
    },
    apiConfig: {
        uri: 'https://dev-webapi.api.euv-stadtbetrieb.de',
        scopes: [
            "api://49b58ab2-5638-4150-8b6b-63cf20e6cdb3/Edit"
        ],
    },
};
