import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Planungstag } from '../api';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-planungstage-edit',
  templateUrl: './planungstage-edit.component.html',
  styleUrls: ['./planungstage-edit.component.scss']
})
export class PlanungstageEditComponent {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  pt: Planungstag = {
    beschreibung: "",
    abkuerzung: "",
  };

  constructor(public dialogRef: MatDialogRef<PlanungstageEditComponent>, @Inject(MAT_DIALOG_DATA) private data: Planungstag) {
    if(data) this.pt = cloneDeep(data);
    if(!this.pt.tags) {
      this.pt.tags = [];
    }
    console.debug("Data:", data);
    console.debug("Saved:", this.pt);
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if(value) {
      this.pt.tags?.push(value);
    }
    event.chipInput!.clear();
  }

  removeTag(tag: string): void {
    const index = this.pt.tags?.indexOf(tag);
    console.debug("RemoveTag:", tag, index);
    if(index!==undefined && index >= 0) {
      this.pt.tags?.splice(index, 1);
      console.debug("After:", this.pt.tags);
    }
  }

  editTag(tag: string, event: MatChipEditedEvent): void {
    const value = event.value.trim();
    if(!value) {
      this.removeTag(tag);
      return;
    }

    const index = this.pt.tags?.indexOf(tag);
    if(index !== undefined && index >= 0 && this.pt.tags) {
      this.pt.tags[index] = value;
    }
  }

  close(d: Planungstag): void {
    if(d.datum) d.datum = DateTime.fromISO(d.datum).toFormat("yyyy-MM-dd");
    if(d.datumStart) d.datumStart = DateTime.fromISO(d.datumStart).toFormat("yyyy-MM-dd");
    if(d.datumEnde) d.datumEnde = DateTime.fromISO(d.datumEnde).toFormat("yyyy-MM-dd");
    this.dialogRef.close(d);
  }
}
