import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OeffnungszeitenGebaeude } from '../api';

@Component({
  selector: 'app-oeffnungszeiten-delete',
  templateUrl: './oeffnungszeiten-delete.component.html',
  styleUrls: ['./oeffnungszeiten-delete.component.scss']
})
export class OeffnungszeitenDeleteComponent {
  constructor(public dialogRef: MatDialogRef<OeffnungszeitenDeleteComponent>, @Inject(MAT_DIALOG_DATA)public data: OeffnungszeitenGebaeude) {

  }
}
