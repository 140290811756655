import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Oeffnungszeiten, OeffnungszeitenGebaeude } from '../api';
import { cloneDeep } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-oeffnungszeiten-edit',
  templateUrl: './oeffnungszeiten-edit.component.html',
  styleUrls: ['./oeffnungszeiten-edit.component.scss'],
})
export class OeffnungszeitenEditComponent {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  ozg: OeffnungszeitenGebaeude = {
    beschreibung: "",
  };

  constructor(public dialogRef: MatDialogRef<OeffnungszeitenEditComponent>, @Inject(MAT_DIALOG_DATA)public data: OeffnungszeitenGebaeude) {
    if(data) this.ozg = cloneDeep(data);
    console.debug("OZG:", this.ozg);
  }

  dropWochentag(event: CdkDragDrop<Oeffnungszeiten>): void {
    console.debug("DragDrop:", event);
    if(this.ozg.oeffnungszeiten) {
      moveItemInArray(this.ozg.oeffnungszeiten, event.previousIndex, event.currentIndex);
    }
  }

  createWochentag(): void {
    if(!this.ozg.oeffnungszeiten) this.ozg.oeffnungszeiten = [];
    this.ozg.oeffnungszeiten.push({
      wochentag: "",
      htmlId: "",
      zeitspanne: [],
    });
  }

  deleteWochentag(index: number, wt: Oeffnungszeiten): void {
    console.log("Delete wochentag:", wt);
    if(this.ozg.oeffnungszeiten) this.ozg.oeffnungszeiten.splice(index, 1);
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if(value) {
      this.ozg.tags?.push(value);
    }
    event.chipInput!.clear();
  }

  removeTag(tag: string): void {
    const index = this.ozg.tags?.indexOf(tag);
    if(index !== undefined && index >= 0) {
      this.ozg.tags?.splice(index, 1);
    }
  }

  editTag(tag: string, event: MatChipEditedEvent): void {
    const value = event.value.trim();
    if(!value) {
      this.removeTag(tag);
      return;
    }

    const index = this.ozg.tags?.indexOf(tag);
    if(index !== undefined && index >= 0 && this.ozg.tags) {
      this.ozg.tags[index] = value;
    }
  }
}
