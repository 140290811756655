import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Planungstag } from '../api';

@Component({
  selector: 'app-planungstage-delete',
  templateUrl: './planungstage-delete.component.html',
  styleUrls: ['./planungstage-delete.component.scss']
})
export class PlanungstageDeleteComponent {

  constructor(public dialogRef: MatDialogRef<PlanungstageDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: Planungstag) {
    
  }
}
