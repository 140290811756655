import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ansprechpartner } from '../api';

@Component({
  selector: 'app-ansprechpartner-delete',
  templateUrl: './ansprechpartner-delete.component.html',
  styleUrls: ['./ansprechpartner-delete.component.scss']
})
export class AnsprechpartnerDeleteComponent {
  constructor(public dialogRef: MatDialogRef<AnsprechpartnerDeleteComponent>, @Inject(MAT_DIALOG_DATA)public data: Ansprechpartner) {

  }
}
